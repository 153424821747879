import { readFileSync } from "fs";
import { bubble, form, magnet, notification } from "./icons";
import { baseUrl } from "./baseUrl";

const css = readFileSync(__dirname + "/style.css", {
  encoding: "utf-8",
}).toString();

export class PopoverElement extends HTMLElement {
  static observedAttributes = [
    "magnet-id",
    "background-color",
    "color",
    "icon",
    "is-sandbox",
  ];

  private hostStyle: CSSStyleDeclaration;
  private variableStyles: CSSStyleSheet;
  private root: ShadowRoot | undefined;
  private popoverButtonIcon: HTMLDivElement;
  private popoverButton: HTMLDivElement;
  private magnetId: string | undefined;
  private isSandbox: boolean | undefined;
  private iframe: HTMLIFrameElement | undefined;

  constructor() {
    super();
    this.variableStyles = new CSSStyleSheet();
    this.variableStyles.replaceSync(`
      :host {
        --background-color: #C5F600;
        --color: #17181C;
      }
    `);

    const hostStyleRule = [...this.variableStyles.cssRules]
      .filter((rule) => rule instanceof CSSStyleRule)
      .find((rule) => rule.selectorText === ":host");

    if (!hostStyleRule) {
      throw new Error("Host style not found.");
    }

    this.hostStyle = hostStyleRule.style;
    this.popoverButtonIcon = document.createElement("div");
    this.popoverButton = document.createElement("div");
  }

  connectedCallback() {
    if (!this.root) {
      this.root = this.attachShadow({ mode: "closed" });
    }

    const style = new CSSStyleSheet();
    style.replaceSync(css);

    this.root.adoptedStyleSheets = [style, this.variableStyles];

    this.popoverButton.classList.add("popover-button");

    this.popoverButtonIcon.classList.add("popover-button-icon");

    this.popoverButton.appendChild(this.popoverButtonIcon);

    this.popoverButton.addEventListener("click", () => {
      this.show();
    });

    this.root.appendChild(this.popoverButton);

    window.addEventListener("message", this.handleMessage);
  }

  private handleMessage = (event: MessageEvent) => {
    if (!this.iframe || event.source !== this.iframe.contentWindow) {
      return;
    }

    if (event.data.type === "pulzio:close") {
      this.popoverButton.classList.remove("hidden");
      this.iframe.classList.add("hidden");

      this.iframe.addEventListener(
        "transitionend",
        () => {
          if (!this.iframe) {
            return;
          }
          this.root?.removeChild(this.iframe);
        },
        {
          once: true,
        },
      );
    }
  };

  private show() {
    if (!this.magnetId) {
      return;
    }
    this.popoverButton.classList.add("hidden");
    this.iframe = document.createElement("iframe");
    this.iframe.src = `${baseUrl}/${this.isSandbox ? "sandbox" : "share"}/${
      this.magnetId
    }?type=popover`;
    this.iframe.classList.add("popover");
    this.iframe.classList.add("hidden");
    requestAnimationFrame(() => {
      this.iframe?.classList.remove("hidden");
    });
    this.root?.appendChild(this.iframe);
  }

  private selectIcon(iconName: string) {
    let icon;
    switch (iconName) {
      case "bubble":
        icon = bubble;
        break;
      case "form":
        icon = form;
        break;
      case "magnet":
        icon = magnet;
        break;
      case "notification":
        icon = notification;
        break;
      default:
        icon = magnet;
        break;
    }

    this.popoverButtonIcon.innerHTML = icon;
  }

  disconnectedCallback() {
    this.root = undefined;
    window.removeEventListener("message", this.handleMessage);
  }

  attributeChangedCallback(name: string, _oldValue: string, newValue: string) {
    switch (name) {
      case "background-color":
        this.hostStyle.setProperty("--background-color", newValue);
        break;
      case "color":
        this.hostStyle.setProperty("--color", newValue);
        break;
      case "icon":
        this.selectIcon(newValue);
        break;
      case "magnet-id":
        this.magnetId = newValue;
        break;
      case "is-sandbox":
        this.isSandbox = newValue === "true";
        break;
    }
  }
}
